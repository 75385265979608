//import apiClient from "../../utils/apiClient";
//import apiClient from "../../utils/dummyApi";
//import axios from "axios";
import { apiBegin, apiSuccess, apiFailure } from "../../logic";

//import { getData } from "../../utils/storageService";

import CONFIG from "../../config";
import apiClient from "../../utils";
import { getApiClientAndUpdateMapping } from "../../utils/apiClient";
import { updateAllSourceMap } from "../../utils/sourceUtils";

const apiConfig = {
  GET_API_SOURCE_LIST: {
    method: "get",
    url: "/dataSources",
    data: {},
  },
};

//const URL = `${process.env.REACT_APP_BACKEND_URL}/api/v1/dataSources`;

const getApiSourceListCall = () => ({
  type: "GET_API_SOURCE_LIST_CALL",
});

const getApiSourceListSuccess = (data) => ({
  type: "GET_API_SOURCE_LIST_SUCCESS",
  data,
});

const getApiSourceListFailure = (error) => ({
  type: "GET_API_SOURCE_LIST_FAILURE",
  error,
});

const defaultState = {
  loading: false,
  isError: false,
  data: null,
  error: null,
  flag: false,
};

const getApiSourceListReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "GET_API_SOURCE_LIST_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: null,
      };
    case "GET_API_SOURCE_LIST_SUCCESS":
      return {
        ...state,
        data: action.data,
        loading: false,
        isError: false,
        flag: true,
      };
    case "GET_API_SOURCE_LIST_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "GET_API_SOURCE_LIST_RESET":
      return {
        ...state,
        loading: false,
        error: null,
        isError: false,
        flag: false,
        data: null,
      };
    default:
      return { ...state };
  }
};

export const startGetApiSourceList = () => (dispatch) => {
  const apiPayload = { ...apiConfig.GET_API_SOURCE_LIST };
  // apiPayload.url += query;
  dispatch(apiBegin());
  dispatch(getApiSourceListCall());
  /*  axios
    .get(URL, {
      headers: {
        Authorization: "Bearer " + `${getData("Token")}`,
      },
    }) */
  apiClient(apiPayload, 0)
    .then((res) => {
      if (process.env.REACT_APP_FINAL_DATA_ONLY !== "true") {
        CONFIG.sourceConfig.push(...res.data.data.results);
      }
      getApiClientAndUpdateMapping();
      updateAllSourceMap();
      dispatch(getApiSourceListSuccess(res.data.data));
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(getApiSourceListFailure(err));
      dispatch(apiFailure(err));
    });
  /* apiClient(apiPayload, 0)
    .then((res) => {
      dispatch(getReportTypeSuccess(res.data.data.results));
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(getReportTypeFailure(err));
      dispatch(apiFailure(err));
    }); */
};

export const getApiSourceListDataReducer = {
  apiSourceListData: getApiSourceListReducer,
};
