/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { withRouter, Route, Redirect } from "react-router-dom";

import { session } from "../../utils";

const AuthRoute = (props) => {
  const { history } = props;
  const { location } = history;
  const redirectUri = location.pathname + (location.search || "");
  const queryParam = new URLSearchParams({ redirectUri }).toString();

  if (session.checkIfLogin()) {
    return <Route {...props} />;
  }

  return (
    <Redirect
      to={{
        pathname: "/login",
        search: `?${queryParam}`,
      }}
    />
  );
};

export default withRouter(AuthRoute);
