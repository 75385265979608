import apiClient from "../../utils/dummyApi";

import { apiBegin, apiSuccess, apiFailure } from "../../logic";

const apiConfig = {
  GET_NEWS_DETAILS: {
    method: "get",
    url: "/news/{NEWS_ID}/",
    data: {},
  },
};

export const resetNewsDetails = () => ({
  type: "GET_NEWS_DETAILS_RESET",
});

const getNewsDetailsCall = () => ({
  type: "GET_NEWS_DETAILS_API_CALL",
});

const getNewsDetailsSuccess = (data) => ({
  type: "GET_NEWS_DETAILS_SUCCESS",
  data,
});

const getNewsDetailsFailure = (error) => ({
  type: "GET_NEWS_DETAILS_FAILURE",
  error,
});

const defaultState = {
  loading: false,
  isError: false,
  data: null,
  error: null,
  flag: false,
};

const getNewsDetailsReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "GET_NEWS_DETAILS_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: null,
      };
    case "GET_NEWS_DETAILS_SUCCESS":
      return {
        ...state,
        data: action.data,
        loading: false,
        isError: false,
        flag: true,
      };
    case "GET_NEWS_DETAILS_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "GET_NEWS_DETAILS_RESET":
      return {
        ...state,
        loading: false,
        error: null,
        isError: false,
        flag: false,
        data: null,
      };
    default:
      return { ...state };
  }
};

const startGetNewsDetails = (fileId, query) => (dispatch) => {
  const apiPayload = { ...apiConfig.GET_NEWS_DETAILS };
  apiPayload.url = apiPayload.url.replace("{NEWS_ID}", fileId);
  dispatch(apiBegin());
  dispatch(getNewsDetailsCall());
  console.log(apiPayload);
  apiClient(apiPayload, 0)
    .then((res) => {
      dispatch(
        getNewsDetailsSuccess(
          /* DUMMY_API_RESPONSE[`/file/2641ee49-7f8f-4859-a2cc-baafcf7bc21b/`]
            .data || */ res.data.data
        )
      );
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(getNewsDetailsFailure(err));
      dispatch(apiFailure(err));
    });
};

export default startGetNewsDetails;

export const newsDetailReducer = {
  newsDetails: getNewsDetailsReducer,
};
