const getUrlParam = () => {
  const searchParam = new URLSearchParams(window.location.search);

  //   !searchParam.has("offset") && searchParam.set("offset", 0);
  //  !searchParam.has("limit") && searchParam.set("limit", 6);
  return searchParam.toString();
};
export const startFilterTable = (data) => ({
  type: "TABLE_UPDATE",
  data,
});

const filterTable = (state, action) => {
  if (action.type === "TABLE_UPDATE") {
    return { searchParam: action.data };
  }

  return {
    searchParam: getUrlParam(),
  };
};

export const FilterTableReducer = {
  searchParamData: filterTable,
};
