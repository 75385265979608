import apiClient, { discardHeader, setHeader } from "./apiClient";
export * as notify from "./notifyUtil";
export * as session from "./sessionManagement";
export function indexOf(list, item) {
  return list.findIndex((x) => JSON.stringify(x) === JSON.stringify(item));
}
export const numberWithCommas = (x = "") => {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "";
};
export const headerUtils = { discardHeader, setHeader };
export default apiClient;
