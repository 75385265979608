import apiClient from "../../utils/apiClient";
//import apiClient from "../../utils/dummyApi";
import { apiBegin, apiSuccess, apiFailure } from "../../logic";

//import CONFIG from "../../config";

const apiConfig = {
  GET_ARTISTS: {
    method: "get",
    url: "/artists/",
    data: {},
  },
  GET_COUNTRIES: {
    method: "get",
    url: "/countries/",
    data: {},
  },
};

//const resetCountriesList = () => ({
//  type: "GET_COUNTRY_RESET",
//});

const getCountriesListexportCall = () => ({
  type: "GET_COUNTRY_API_CALL",
});

const getCountriesListSuccess = (data) => ({
  type: "GET_COUNTRY_SUCCESS",
  data,
});

const getCountriesListFailure = (error) => ({
  type: "GET_COUNTRY_FAILURE",
  error,
});

const defaultState = {
  loading: false,
  isError: false,
  data: null,
  error: null,
  flag: false,
};

const getCountriesListReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "GET_COUNTRY_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: null,
      };
    case "GET_COUNTRY_SUCCESS":
      return {
        ...state,
        data: action.data,
        loading: false,
        isError: false,
        flag: true,
      };
    case "GET_COUNTRY_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "GET_COUNTRY_RESET":
      return {
        ...state,
        loading: false,
        error: null,
        isError: false,
        flag: false,
        data: null,
      };
    default:
      return { ...state };
  }
};

export const startGetCountriesList = () => (dispatch) => {
  const apiPayload = { ...apiConfig.GET_COUNTRIES };
  // apiPayload.url += query;
  dispatch(apiBegin());
  dispatch(getCountriesListexportCall());
  apiClient(apiPayload, 0)
    .then((res) => {
      dispatch(getCountriesListSuccess(res.data.data));
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(getCountriesListFailure(err));
      dispatch(apiFailure(err));
    });
};

const getUrlParam = () => {
  const searchParam = new URLSearchParams(window.location.search);
  searchParam.delete("advanceSearch");
  searchParam.delete("source");
  searchParam.delete("name");
  !searchParam.has("offset") && searchParam.set("offset", 0);
  !searchParam.has("limit") && searchParam.set("limit", 18);

  return searchParam.toString();
};
export const startArtistSearch = (data) => ({
  type: "ARTIST_SEARCH_UPDATE",
  data,
});

const StartArtistSearchReducer = (state, action) => {
  if (action.type === "ARTIST_SEARCH_UPDATE") {
    return { searchParamForArtist: action.data };
  }
  return {
    searchParamForArtist: getUrlParam(),
  };
};

export const getArtistListReducer = {
  countriesList: getCountriesListReducer,
  artistSearchParamData: StartArtistSearchReducer,
};
