import CONFIG from "../config";
//const apiClientList = CONFIG.sourceConfig.filter((source) => source.isActive);
export const apiClientSourceMapping = new Map();

export const apiClientList = CONFIG.sourceConfig
  .filter((source) => source.isActive)
  .map((source) => {
    const apiMocker = (config) => apiClient(config, 0);
    apiClientSourceMapping.set(source.sourceName, apiMocker);
    return apiMocker;
  });
export const DUMMY_API_RESPONSE = {
  "/artworks/": {
    data: {
      results: [
        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          title: "Testina (Little Head)",
          creationYear: 1955,
          medium: "Glazed Ceramic",
          categoryId: "Sculptures And Ceramics",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          artistName: "Lucio Fontana",
        },
        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          title: "After Pablo Picasso - Visages",
          creationYear: 1971,
          medium: "Lithograph In Colors",
          categoryId: "Prints",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/Pablo-Picasso-Visage-Au-Trait-Oblique-Alain-Ramie-587-1968.jpg",
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          artistName: "Pablo Picasso",
        },
        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          title:
            "A Los Toros Avec Picasso By Jaime Sabartes (B. 1014-47; C. Bk. 113)",
          creationYear: 1930,
          medium: "Lithographs",
          categoryId: "Prints",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/Pablo-Picasso-A-Los-Toros-1961.jpg",
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          artistName: "Pablo Picasso",
        },
        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          title: "Man With Hat And Brush",
          creationYear: null,
          medium: "Lithograph In Colors ",
          categoryId: "Prints",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/Pablo-Picasso-Man-with-Hat-and-Brush.jpg",
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          artistName: "Pablo Picasso",
        },
        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          title: "Femme Couchee Et Homme Au Grand Chapeau",
          creationYear: 1959,
          medium: "Linocut In Colors On Arches Wove Paper",
          categoryId: "Prints",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/Pablo-Picasso-Femme-couchee-et-homme-au-grand-chapeau-1959.jpg",
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          artistName: "Pablo Picasso",
        },
        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          title: "Testina (Little Head)",
          creationYear: 1955,
          medium: "Glazed Ceramic",
          categoryId: "Sculptures And Ceramics",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          artistName: "Lucio Fontana",
        },

        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          title: "After Pablo Picasso - Visages",
          creationYear: 1971,
          medium: "Lithograph In Colors",
          categoryId: "Prints",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/Pablo-Picasso-Visage-Au-Trait-Oblique-Alain-Ramie-587-1968.jpg",
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          artistName: "Pablo Picasso",
        },
      ],
      total: 7,
    },
  },
  "/artworks/f912b149-e7a0-49dc-bf79-e5b60c03d038/details/": {
    data: {
      artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
      title: "Testina (Little Head)",
      creationYear: 1955,
      description: "<p>lorem ipsum<em>lorem ipsum</em></p>",
      medium: "oil on canvas",
      categoryId: "Sculptures And Ceramics",
      artistName: "Lucio Fontana",
      artistId: "string",
      dimensions: [
        {
          height: "100",
          width: "10",
          depth: "5",
          unit: "mm",
          key: "frame",
          originalStr: "100 x 10 x 5 mm",
        },
        {
          height: "100",
          width: "10",
          depth: "5",
          unit: "mm",
          key: "foil",
          originalStr: "100 x 10 x 5 mm",
        },
      ],
      auctionRecords: [
        {
          auctionEventName: "string",
          auctionHouseName: "string",
          auctionLocation: "string",
          lot: "string",
          isSold: true,
          saleDate: "string",
          price: {
            premiumPriceSold: 10,
            premiumPriceCurrency: "USD",
            hammerPriceSold: 10,
            hammerPriceCurrency: "USD",
            estimateMin: 5,
            estimateMax: 10,
            estimateCurrency: "USD",
          },
          guaranteedLot: {
            isGuaranteed: true,
            guaranteedPrice: "string",
            guaranteedConditions: "string",
          },
          premiumLot: {
            isPremium: true,
            premiumLotDetails: "string",
          },
        },
      ],
      literature: "<p>lorem ipsum<em>lorem ipsum</em>literature</p>",
      provenance: "<p>lorem ipsum<em>lorem ipsum</em> provenance</p>",
      exhibitions: "<p>lorem ipsum<em>lorem ipsum</em>exhibitions</p>",
      condition: "<p>lorem ipsum<em>lorem ipsum</em>condition</p>",
      images: [
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          size: "small",
        },
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          size: "small",
        },
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          size: "small",
        },
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          size: "small",
        },
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          size: "small",
        },
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          size: "small",
        },
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          size: "small",
        },
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          size: "small",
        },
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/auction_artwork_images/43590928.jpg",
          size: "small",
        },
      ],
      currentPredictedPrice: 0,
      currentPredictedPriceCurrency: "USD",
    },
  },
  "/artworks/f912b149-e7a0-49dc-bf79-e5b60c03d038/metadata/": {
    data: {
      artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
      results: [
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
      ],
    },
  },
  "/artists/": {
    data: {
      results: [
        {
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          name: "Pablo Picasso",
          birthYear: 1930,
          deathYear: 1940,
          nationality: ["US", "FR", "Irish"],
          gender: "M|F",
          image:
            "https://mymodernmet.com/wp/wp-content/uploads/2019/02/pablo-picasso-facts.jpg",
          artworksCount: 0,
        },
        {
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          name: "Pablo Picasso",
          birthYear: 1930,
          deathYear: 1940,
          nationality: ["US", "FR", "Irish"],
          gender: "M|F",
          image:
            "https://mymodernmet.com/wp/wp-content/uploads/2019/02/pablo-picasso-facts.jpg",
          artworksCount: 0,
        },
        {
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          name: "Pablo Picasso",
          birthYear: 1930,
          deathYear: 1940,
          nationality: ["US", "FR", "Irish"],
          gender: "M|F",
          image:
            "https://mymodernmet.com/wp/wp-content/uploads/2019/02/pablo-picasso-facts.jpg",
          artworksCount: 0,
        },
        {
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          name: "Pablo Picasso",
          birthYear: 1930,
          deathYear: 1940,
          nationality: ["US", "FR", "Irish"],
          gender: "M|F",
          image:
            "https://mymodernmet.com/wp/wp-content/uploads/2019/02/pablo-picasso-facts.jpg",
          artworksCount: 0,
        },
        {
          artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          name: "Pablo Picasso",
          birthYear: 1930,
          deathYear: 1940,
          nationality: ["US", "FR", "Irish"],
          gender: "M|F",
          image:
            "https://mymodernmet.com/wp/wp-content/uploads/2019/02/pablo-picasso-facts.jpg",
          artworksCount: 0,
        },
      ],
      total: 5,
    },
  },
  "/artists/f912b149-e7a0-49dc-bf79-e5b60c03d038/details/": {
    data: {
      artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
      name: "string",
      birthYear: 1930,
      deathYear: 1940,
      birthPlace: "New York",
      deathPlace: "New York",
      nationality: ["US", "FR", "Irish"],
      gender: "M|F",
      images: [
        {
          url: "https://mymodernmet.com/wp/wp-content/uploads/2019/02/pablo-picasso-facts.jpg",
          size: "small",
        },
        {
          url: "https://cdn.britannica.com/63/59963-050-C03F29B9/Pablo-Picasso.jpg",
          size: "small",
        },
      ],
      artworksCount: 0,
    },
  },
  "/artists/f912b149-e7a0-49dc-bf79-e5b60c03d038/metadata/": {
    data: {
      artistId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
      results: [
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
        {
          changesMade: "string",
          changeType: "string",
          updatedAt: "string",
        },
      ],
    },
  },
  "/categories/": {
    data: {
      results: [
        {
          id: "f912b149-e7a0-49dc-bf79-e5b60c03d033",
          name: "Painting1",
          info: "Paintings and Artworks",
        },
        {
          id: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          name: "Painting2",
          info: "Paintings and Artworks",
        },
        {
          id: "f912b149-e7a0-49dc-bf79-e5b60c03d032",
          name: "Painting3",
          info: "Paintings and Artworks",
        },
        {
          id: "f912b149-e7a0-49dc-bf79-e5b60c03d036",
          name: "Painting4",
          info: "Paintings and Artworks",
        },
      ],
      total: 4,
    },
  },
  "/countries/": {
    data: {
      results: [
        {
          id: "IN",
          name: "India",
          is_iso: true,
        },
        {
          id: "US",
          name: "United States",
          is_iso: true,
        },
        {
          id: "EN",
          name: "England",
          is_iso: true,
        },
      ],
      total: 0,
    },
  },
  "/news/": {
    data: {
      results: [
        {
          newsId: "f912b149-e7a0-49dc-bf79-e5b60c03d038ab",
          title: `Met Museum’s Deaccession Picasso Sculpture Expected to Fetch $30 M. at Christie’s in May`,
          newsDate: "24-10-1930",
          content: `The Metropolitan Museum of Art in New York is selling a valuable bronze cast of a woman’s head by Pablo Picasso, dated to 1909, at Christie’s this May. The museum said that it is deaccessioning the early piece, which is the first sculpture the artist ever produced in his Cubist-style and has been in the collection for more than two decades, because it owns another version of the same work that was recently donated by Leonard Lauder, one of the world’s top collectors of Cubist art.
     According to a Christie’s spokesperson, the work’s value carries an estimate upon request and is expected to fetch around $30 million. It will be sold during a New York 20th Century Art evening sale; the auction’s exact date has not yet been announced.
In a statement, the Met said the proceeds from the sale of Tête de femme (Fernande) will go toward acquisitions of new works for the museum’s permanent collection, aligning with a policy set in place by the Association of Art Museum Directors (AAMD), an organization that designates rules for art institutions operating in the U.S. The Met said it deaccessions works valued over $50,000 on an annual basis, after its curators review the permanent collection for duplicate works that are eligible to then be sold.,
`,

          images: [
            {
              url: "https://www.artnews.com/wp-content/uploads/2022/03/Screen-Shot-2022-03-09-at-12.18.06-PM.png",
              size: "small",
            },
          ],
          source_url:
            "https://www.artnews.com/art-news/news/picasso-cubist-sculpture-metropolitan-museum-deaccession-christies-1234621446/",
          source_name: "Art news",
          relatedArtists: ["string"],
          relatedArtworks: ["string"],
          relatedAuctionRecords: ["string"],
        },
        {
          newsId: "f912b149-e7a0-49dc-bf79-e5b60c03d038ac",
          title: `Museum of Modern Art Evacuated After Stabbing of Two Employees`,
          newsDate: "24-10-1930",
          content: `The Metropolitan Museum of Art in New York is selling a valuable bronze cast of a woman’s head by Pablo Picasso, dated to 1909, at Christie’s this May. The museum said that it is deaccessioning the early piece, which is the first sculpture the artist ever produced in his Cubist-style and has been in the collection for more than two decades, because it owns another version of the same work that was recently donated by Leonard Lauder, one of the world’s top collectors of Cubist art.
     According to a Christie’s spokesperson, the work’s value carries an estimate upon request and is expected to fetch around $30 million. It will be sold during a New York 20th Century Art evening sale; the auction’s exact date has not yet been announced.
In a statement, the Met said the proceeds from the sale of Tête de femme (Fernande) will go toward acquisitions of new works for the museum’s permanent collection, aligning with a policy set in place by the Association of Art Museum Directors (AAMD), an organization that designates rules for art institutions operating in the U.S. The Met said it deaccessions works valued over $50,000 on an annual basis, after its curators review the permanent collection for duplicate works that are eligible to then be sold.,
`,

          images: [
            {
              url: "https://www.artnews.com/wp-content/uploads/2021/04/01_MoMA_Photography-by-Iwan-Baan-2000x1333-1.jpg",
              size: "small",
            },
          ],
          source_url:
            "https://www.artnews.com/art-news/news/moma-evacuated-stabbing-1234621764/",
          source_name: "Art news",
          relatedArtists: ["string"],
          relatedArtworks: ["string"],
          relatedAuctionRecords: ["string"],
        },
        {
          newsId: "f912b149-e7a0-49dc-bf79-e5b60c03d038ad",
          title: `Two people stabbed at world-renowned New York's Museum of Modern Art`,

          newsDate: "24-10-1930",
          content: `Two employees were brutally stabbed at New Yorks's Museum of Modern Art this afternoon. The building was immediately evacuated, victims were hospitalized, and all protocols were duly followed. The suspect is yet to be arrested.`,
          images: [
            {
              url: "https://img.etimg.com/thumb/msid-90187462,width-300,imgsize-153030,,resizemode-4,quality-100/new-york-museum-stabbing.jpg",
              size: "small",
            },
          ],
          source_url:
            "https://economictimes.indiatimes.com/news/international/us/two-people-stabbed-at-world-renowned-new-yorks-museum-of-modern-art/articleshow/90187481.cms",
          source_name: "The Economics Times",
          relatedArtists: ["string"],
          relatedArtworks: ["string"],
          relatedAuctionRecords: ["string"],
        },
      ],
      total: 3,
    },
  },
  "/news/f912b149-e7a0-49dc-bf79-e5b60c03d038ab/details": {
    data: {
      newsId: "f912b149-e7a0-49dc-bf79-e5b60c03d038ab",
      title: "Year-end deadline for multilevel parking may be good news",
      newsDate: "24-10-1930",
      content: `The Metropolitan Museum of Art in New York is selling a valuable bronze cast of a woman’s head by Pablo Picasso, dated to 1909, at Christie’s this May. The museum said that it is deaccessioning the early piece, which is the first sculpture the artist ever produced in his Cubist-style and has been in the collection for more than two decades, because it owns another version of the same work that was recently donated by Leonard Lauder, one of the world’s top collectors of Cubist art.
     According to a Christie’s spokesperson, the work’s value carries an estimate upon request and is expected to fetch around $30 million. It will be sold during a New York 20th Century Art evening sale; the auction’s exact date has not yet been announced.
In a statement, the Met said the proceeds from the sale of Tête de femme (Fernande) will go toward acquisitions of new works for the museum’s permanent collection, aligning with a policy set in place by the Association of Art Museum Directors (AAMD), an organization that designates rules for art institutions operating in the U.S. The Met said it deaccessions works valued over $50,000 on an annual basis, after its curators review the permanent collection for duplicate works that are eligible to then be sold.,
`,
      images: [
        {
          originalUrl:
            "https://www.artnews.com/wp-content/uploads/2022/03/Screen-Shot-2022-03-09-at-12.18.06-PM.png",
          size: "small",
        },
        {
          originalUrl:
            "https://mymodernmet.com/wp/wp-content/uploads/2019/02/pablo-picasso-facts.jpg",
          size: "small",
        },
      ],
      source_url: "string",
      source_name: "Art news",
      relatedArtists: ["string"],
      relatedArtworks: ["string"],
      relatedAuctionRecords: ["string"],
    },
  },
  "/news/f912b149-e7a0-49dc-bf79-e5b60c03d038ac/details": {
    data: {
      newsId: "f912b149-e7a0-49dc-bf79-e5b60c03d038ac",
      title: "Year-end deadline for multilevel parking may be good news.",
      newsDate: "24-10-1930",
      content: `The Metropolitan Museum of Art in New York is selling a valuable bronze cast of a woman’s head by Pablo Picasso, dated to 1909, at Christie’s this May. The museum said that it is deaccessioning the early piece, which is the first sculpture the artist ever produced in his Cubist-style and has been in the collection for more than two decades, because it owns another version of the same work that was recently donated by Leonard Lauder, one of the world’s top collectors of Cubist art.
     According to a Christie’s spokesperson, the work’s value carries an estimate upon request and is expected to fetch around $30 million. It will be sold during a New York 20th Century Art evening sale; the auction’s exact date has not yet been announced.
In a statement, the Met said the proceeds from the sale of Tête de femme (Fernande) will go toward acquisitions of new works for the museum’s permanent collection, aligning with a policy set in place by the Association of Art Museum Directors (AAMD), an organization that designates rules for art institutions operating in the U.S. The Met said it deaccessions works valued over $50,000 on an annual basis, after its curators review the permanent collection for duplicate works that are eligible to then be sold.,
`,
      images: [
        {
          originalUrl:
            "https://www.artnews.com/wp-content/uploads/2021/04/01_MoMA_Photography-by-Iwan-Baan-2000x1333-1.jpg",
          size: "small",
        },
        {
          originalUrl:
            "https://mymodernmet.com/wp/wp-content/uploads/2019/02/pablo-picasso-facts.jpg",
          size: "small",
        },
      ],
      source_url: "string",
      source_name: "Art news",
      relatedArtists: ["string"],
      relatedArtworks: ["string"],
      relatedAuctionRecords: ["string"],
    },
  },
  "/news/f912b149-e7a0-49dc-bf79-e5b60c03d038ad/details": {
    data: {
      newsId: "f912b149-e7a0-49dc-bf79-e5b60c03d038ad",
      title: "Year-end deadline for multilevel parking may be good news.",
      newsDate: "24-10-1930",
      content:
        "The southern tech hub of Shenzhen told all residents to stay at home as the city struggles to eradicate an Omicron flare-up linked to neighbouring virus-ravaged Hong Kong.",
      images: [
        {
          originalUrl:
            "https://img.etimg.com/thumb/msid-90187462,width-300,imgsize-153030,,resizemode-4,quality-100/new-york-museum-stabbing.jpg",
          size: "small",
        },
        {
          originalUrl:
            "https://mymodernmet.com/wp/wp-content/uploads/2019/02/pablo-picasso-facts.jpg",
          size: "small",
        },
      ],
      source_url: "string",
      source_name: "string",
      relatedArtists: ["string"],
      relatedArtworks: ["string"],
      relatedAuctionRecords: ["string"],
    },
  },
  "/news/?artworkId=74e6c1511fa37e53c1786654850827050c358415838b0911992e031f&limit=5":
    {
      data: {
        results: [
          {
            newsId: "f912b149-e7a0-49dc-bf79-e5b60c03d038ab",
            title: `Met Museum’s Deaccession Picasso Sculpture Expected to Fetch $30 M. at Christie’s in May`,
            newsDate: "24-10-1930",
            content: `The Metropolitan Museum of Art in New York is selling a valuable bronze cast of a woman’s head by Pablo Picasso, dated to 1909, at Christie’s this May. The museum said that it is deaccessioning the early piece, which is the first sculpture the artist ever produced in his Cubist-style and has been in the collection for more than two decades, because it owns another version of the same work that was recently donated by Leonard Lauder, one of the world’s top collectors of Cubist art.
     According to a Christie’s spokesperson, the work’s value carries an estimate upon request and is expected to fetch around $30 million. It will be sold during a New York 20th Century Art evening sale; the auction’s exact date has not yet been announced.
In a statement, the Met said the proceeds from the sale of Tête de femme (Fernande) will go toward acquisitions of new works for the museum’s permanent collection, aligning with a policy set in place by the Association of Art Museum Directors (AAMD), an organization that designates rules for art institutions operating in the U.S. The Met said it deaccessions works valued over $50,000 on an annual basis, after its curators review the permanent collection for duplicate works that are eligible to then be sold.,
`,

            images: [
              {
                originalUrl:
                  "https://www.artnews.com/wp-content/uploads/2022/03/Screen-Shot-2022-03-09-at-12.18.06-PM.png",
                size: "small",
              },
            ],
            source_url:
              "https://www.artnews.com/art-news/news/picasso-cubist-sculpture-metropolitan-museum-deaccession-christies-1234621446/",
            source_name: "Art news",
            relatedArtists: ["string"],
            relatedArtworks: ["string"],
            relatedAuctionRecords: ["string"],
          },
          {
            newsId: "f912b149-e7a0-49dc-bf79-e5b60c03d038ac",
            title: `Museum of Modern Art Evacuated After Stabbing of Two Employees`,
            newsDate: "24-10-1930",
            content: `The Metropolitan Museum of Art in New York is selling a valuable bronze cast of a woman’s head by Pablo Picasso, dated to 1909, at Christie’s this May. The museum said that it is deaccessioning the early piece, which is the first sculpture the artist ever produced in his Cubist-style and has been in the collection for more than two decades, because it owns another version of the same work that was recently donated by Leonard Lauder, one of the world’s top collectors of Cubist art.
     According to a Christie’s spokesperson, the work’s value carries an estimate upon request and is expected to fetch around $30 million. It will be sold during a New York 20th Century Art evening sale; the auction’s exact date has not yet been announced.
In a statement, the Met said the proceeds from the sale of Tête de femme (Fernande) will go toward acquisitions of new works for the museum’s permanent collection, aligning with a policy set in place by the Association of Art Museum Directors (AAMD), an organization that designates rules for art institutions operating in the U.S. The Met said it deaccessions works valued over $50,000 on an annual basis, after its curators review the permanent collection for duplicate works that are eligible to then be sold.,
`,

            images: [
              {
                originalUrl:
                  "https://www.artnews.com/wp-content/uploads/2021/04/01_MoMA_Photography-by-Iwan-Baan-2000x1333-1.jpg",
                size: "small",
              },
            ],
            source_url:
              "https://www.artnews.com/art-news/news/moma-evacuated-stabbing-1234621764/",
            source_name: "Art news",
            relatedArtists: ["string"],
            relatedArtworks: ["string"],
            relatedAuctionRecords: ["string"],
          },
          {
            newsId: "f912b149-e7a0-49dc-bf79-e5b60c03d038ad",
            title: `Two people stabbed at world-renowned New York's Museum of Modern Art`,

            newsDate: "24-10-1930",
            content: `Two employees were brutally stabbed at New Yorks's Museum of Modern Art this afternoon. The building was immediately evacuated, victims were hospitalized, and all protocols were duly followed. The suspect is yet to be arrested.`,
            images: [
              {
                originalUrl:
                  "https://img.etimg.com/thumb/msid-90187462,width-300,imgsize-153030,,resizemode-4,quality-100/new-york-museum-stabbing.jpg",
                size: "small",
              },
            ],
            source_url:
              "https://economictimes.indiatimes.com/news/international/us/two-people-stabbed-at-world-renowned-new-yorks-museum-of-modern-art/articleshow/90187481.cms",
            source_name: "The Economics Times",
            relatedArtists: ["string"],
            relatedArtworks: ["string"],
            relatedAuctionRecords: ["string"],
          },
        ],
        total: 3,
      },
    },

  "/incidentRecords/": {
    data: {
      results: [
        {
          incidentId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          title: "Torero",
          lostNFoundRecordDate: "24-10-1930",
          description: `Signed in the lower right corner in green crayon Picasso and numbered in pencil in the lower left corner in Roman numerals IX of XXX. Also dated 30AA9.III (1949) in the plate lower right corner. REFERENCE # 00287`,
          creationYear: 1930,
          category: "Painting",
          medium: "Oil on canvas",
          dimensions: [
            {
              height: "100",
              width: "10",
              depth: "5",
              unit: "mm",
              key: "frame|foil",
              originalStr: "100 x 10 x 5 mm",
            },
          ],
          images: [
            {
              url: "https://www.fbi.gov/investigate/violent-crime/art-theft/national-stolen-art-file/@@artcrimes-thumbnail?filename=Torero.jpg&selected=-1",
              size: "small",
            },
          ],
          location: "New York",
          status: "Lost",
          source_url: "string",
          source_name: "FBI",
          relatedArtists: ["string"],
          relatedArtworks: ["string"],
        },
        {
          incidentId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          title: "Torero",
          lostNFoundRecordDate: "24-10-1930",
          description: `Signed in the lower right corner in green crayon Picasso and numbered in pencil in the lower left corner in Roman numerals IX of XXX. Also dated 30AA9.III (1949) in the plate lower right corner. REFERENCE # 00287`,
          creationYear: 1930,
          category: "Painting",
          medium: "Oil on canvas",
          dimensions: [
            {
              height: "100",
              width: "10",
              depth: "5",
              unit: "mm",
              key: "frame|foil",
              originalStr: "100 x 10 x 5 mm",
            },
          ],
          images: [
            {
              url: "https://www.fbi.gov/investigate/violent-crime/art-theft/national-stolen-art-file/@@artcrimes-thumbnail?filename=Torero.jpg&selected=-1",
              size: "small",
            },
          ],
          location: "New York",
          status: "Lost",
          source_url: "string",
          source_name: "FBI",
          relatedArtists: ["string"],
          relatedArtworks: ["string"],
        },
      ],
      total: 2,
    },
  },
  "/incidentRecords/f912b149-e7a0-49dc-bf79-e5b60c03d038/details": {
    data: {
      incidentId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
      title: "Torero",
      incidentRecordDate: "24-10-1930",
      description: `The Metropolitan Museum of Art in New York is selling a valuable bronze cast of a woman’s head by Pablo Picasso, dated to 1909, at Christie’s this May. The museum said that it is deaccessioning the early piece, which is the first sculpture the artist ever produced in his Cubist-style and has been in the collection for more than two decades, because it owns another version of the same work that was recently donated by Leonard Lauder, one of the world’s top collectors of Cubist art.
     According to a Christie’s spokesperson, the work’s value carries an estimate upon request and is expected to fetch around $30 million. It will be sold during a New York 20th Century Art evening sale; the auction’s exact date has not yet been announced.
In a statement, the Met said the proceeds from the sale of Tête de femme (Fernande) will go toward acquisitions of new works for the museum’s permanent collection, aligning with a policy set in place by the Association of Art Museum Directors (AAMD), an organization that designates rules for art institutions operating in the U.S. The Met said it deaccessions works valued over $50,000 on an annual basis, after its curators review the permanent collection for duplicate works that are eligible to then be sold.`,
      creationYear: 1930,
      category: "Painting",
      medium: "Oil on canvas",
      dimensions: "51.5 x 38.2 cm",
      images: [
        {
          url: "https://www.fbi.gov/investigate/violent-crime/art-theft/national-stolen-art-file/@@artcrimes-thumbnail?filename=Torero.jpg&selected=-1",
          size: "small",
        },
      ],
      location: "New York",
      incidentType: "Lost",
      source_url: "string",
      source_name: "FBI",
      relatedArtists: ["string"],
      relatedArtworks: ["string"],
    },
  },
  "/data-change-suggestions/": {
    data: {
      message: "Suggestion added successfully",
    },
  },
  "/IncidentRecords/?artworkId=74e6c1511fa37e53c1786654850827050c358415838b0911992e031f&limit=5":
    {
      data: {
        results: [
          {
            incidentId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
            title: "Torero",
            lostNFoundRecordDate: "24-10-1930",
            description: `Signed in the lower right corner in green crayon Picasso and numbered in pencil in the lower left corner in Roman numerals IX of XXX. Also dated 30AA9.III (1949) in the plate lower right corner. REFERENCE # 00287`,
            creationYear: 1930,
            category: "Painting",
            medium: "Oil on canvas",
            dimensions: [
              {
                height: "100",
                width: "10",
                depth: "5",
                unit: "mm",
                key: "frame|foil",
                originalStr: "100 x 10 x 5 mm",
              },
            ],
            images: [
              {
                url: "https://www.fbi.gov/investigate/violent-crime/art-theft/national-stolen-art-file/@@artcrimes-thumbnail?filename=Torero.jpg&selected=-1",
                size: "small",
              },
            ],
            location: "New York",
            status: "Lost",
            source_url: "string",
            source_name: "FBI",
            relatedArtists: ["string"],
            relatedArtworks: ["string"],
          },
          {
            incidentId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
            title: "Torero",
            lostNFoundRecordDate: "24-10-1930",
            description: `Signed in the lower right corner in green crayon Picasso and numbered in pencil in the lower left corner in Roman numerals IX of XXX. Also dated 30AA9.III (1949) in the plate lower right corner. REFERENCE # 00287`,
            creationYear: 1930,
            category: "Painting",
            medium: "Oil on canvas",
            dimensions: [
              {
                height: "100",
                width: "10",
                depth: "5",
                unit: "mm",
                key: "frame|foil",
                originalStr: "100 x 10 x 5 mm",
              },
            ],
            images: [
              {
                url: "https://www.fbi.gov/investigate/violent-crime/art-theft/national-stolen-art-file/@@artcrimes-thumbnail?filename=Torero.jpg&selected=-1",
                size: "small",
              },
            ],
            location: "New York",
            status: "Lost",
            source_url: "string",
            source_name: "FBI",
            relatedArtists: ["string"],
            relatedArtworks: ["string"],
          },
        ],
        total: 2,
      },
    },

  "/data-change-suggestion/": {
    data: {
      results: [
        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          comment:
            "sample comment one sample comment one sample comment one sample comment one sample comment one",
          createdAt: "2020-06-01T12:00:00.000Z",
          status: "pending",
        },
        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          comment:
            "sample comment two sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one",
          createdAt: "2020-06-01T12:00:00.000Z",
          status: "pending",
        },
        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          comment:
            "sample comment three sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one",
          createdAt: "2020-06-01T12:00:00.000Z",
          status: "pending",
        },
        {
          artworkId: "f912b149-e7a0-49dc-bf79-e5b60c03d038",
          comment:
            "sample comment four sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one sample comment one",
          createdAt: "2020-06-01T12:00:00.000Z",
          status: "Changes Incorporated",
        },
      ],
      total: 4,
    },
  },
  "/reports/": {
    data: {
      results: [
        {
          reportId: "dywgd37r632r",
          reportName: "sample",
          reportType: "sample",
          createdOn: `${new Date().toISOString()}`,
          status: "success",
          action: "view",
        },
        {
          reportId: "jdj85865985",
          reportName: "sample",
          reportType: "sample",
          createdOn: `${new Date().toISOString()}`,
          status: "success",
          action: "view",
        },
        {
          reportId: "hudehfd8437843",
          reportName: "sample",
          reportType: "sample",
          createdOn: `${new Date().toISOString()}`,
          status: "processing",
          action: "view",
        },
        {
          reportId: "lkodjewid745764",
          reportName: "sample",
          reportType: "sample",
          createdOn: `${new Date().toISOString()}`,
          status: "failed",
          action: "view",
        },
      ],
    },
  },
  "/institutes/": {
    data: {
      results: [
        {
          name: "Il Ponte Casa D'aste",
          city: "Milan",
          country: "Italy",
          // nationality: ["US", "FR", "Irish"],
          address: "Via Pitteri 10, Milan",
          website: "Il Ponte Casa D'aste",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Il_Ponte_Casa_DAste-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-945843hr43",
        },
        {
          name: "Heritage Auctions",
          city: "Dallas, Texas 75261-4127",
          country: "United States Of America",
          // nationality: ["US", "FR", "Irish"],
          address: "2801 W Airport Fwy",
          website: "Heritage Auctions",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Heritage_Exterior-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-894ru438r",
        },
        {
          name: "Artcurial Paris",
          city: "Paris",
          country: "France",
          // nationality: ["US", "FR", "Irish"],
          address: "7 Rond-Point Des Champs-Elysées",
          website: "Artcurial Paris",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Artcurial-Paris-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-9845843hfj",
        },
        {
          name: "Menzies Art Brands Melbourne",
          city: "Melbourne",
          country: "Australia",
          // nationality: ["US", "FR", "Irish"],
          address: "1 Darling Street",
          website: "Menzies Art Brands Melbourneey",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Menzies_art_brands_Melbourne-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-094t945thf",
        },
        {
          name: "Menzies Art Brands Sydney",
          city: "Sydney",
          country: "Australia",
          // nationality: ["US", "FR", "Irish"],
          address: "12 Todman Avenue",
          website: "Menzies Art Brands Sydney",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Menzies_art_brands_Sydney-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-094954jfe",
        },
        {
          name: "Menzies Art Brands Melbourne",
          city: "Melbourne",
          country: "Australia",
          // nationality: ["US", "FR", "Irish"],
          address: "1 Darling Street",
          website: "Menzies Art Brands Melbourneey",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Menzies_art_brands_Melbourne-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-094t945thf",
        },
        {
          name: "Il Ponte Casa D'aste",
          city: "Milan",
          country: "Italy",
          // nationality: ["US", "FR", "Irish"],
          address: "Via Pitteri 10, Milan",
          website: "Il Ponte Casa D'aste",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Il_Ponte_Casa_DAste-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-945843hr43",
        },
        {
          name: "Heritage Auctions",
          city: "Dallas, Texas 75261-4127",
          country: "United States Of America",
          // nationality: ["US", "FR", "Irish"],
          address: "2801 W Airport Fwy",
          website: "Heritage Auctions",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Heritage_Exterior-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-894ru438r",
        },
        {
          name: "Artcurial Paris",
          city: "Paris",
          country: "France",
          // nationality: ["US", "FR", "Irish"],
          address: "7 Rond-Point Des Champs-Elysées",
          website: "Artcurial Paris",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Artcurial-Paris-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-9845843hfj",
        },
        {
          name: "Menzies Art Brands Melbourne",
          city: "Melbourne",
          country: "Australia",
          // nationality: ["US", "FR", "Irish"],
          address: "1 Darling Street",
          website: "Menzies Art Brands Melbourneey",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Menzies_art_brands_Melbourne-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-094t945thf",
        },
        {
          name: "Menzies Art Brands Sydney",
          city: "Sydney",
          country: "Australia",
          // nationality: ["US", "FR", "Irish"],
          address: "12 Todman Avenue",
          website: "Menzies Art Brands Sydney",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Menzies_art_brands_Sydney-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-094954jfe",
        },
        {
          name: "Menzies Art Brands Melbourne",
          city: "Melbourne",
          country: "Australia",
          // nationality: ["US", "FR", "Irish"],
          address: "1 Darling Street",
          website: "Menzies Art Brands Melbourneey",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Menzies_art_brands_Melbourne-thumbnail_webp-558x279.webp",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-094t945thf",
        },
      ],
      total: 12,
    },
  },
  "/institutes/f912b149-e7a0-49dc-bf79-945843hr43/details/": {
    data: {
      name: "Il Ponte Casa D'aste",
      city: "Milan",
      country: "Italy",
      // nationality: ["US", "FR", "Irish"],
      address: "Via Pitteri 10, Milan",
      website: "https://www.ponteonline.com/en",

      instituteId: "f912b149-e7a0-49dc-bf79-945843hr43",
      images: [
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Il_Ponte_Casa_DAste-thumbnail_webp-558x279.webp",
          size: "small",
        },
        {
          url: "https://d16kd6gzalkogb.cloudfront.net/__sized__/venue_images/Il_Ponte_Casa_DAste-thumbnail_webp-558x279.webp",
          size: "small",
        },
      ],
      artworksCount: 0,
    },
  },
  "/institutes/f912b149-e7a0-49dc-bf79-945843hr43/auctionEvents/": {
    data: {
      results: [
        {
          name: "Decorative Arts Of The 20th Century And Design",
          category: "Auctions",
          city: "",
          //country: "",
          // nationality: ["US", "FR", "Irish"],
          address: "",
          // website: "",
          Date: "07/23/2020",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/event_images/Ponte_Decorative_Art_Featured_image_I5ryJaW.jpg",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-945843hr43",
          eventId: "f912b149-e7a0-49dc-bf79-995t45jf",
        },
        {
          name: "Decorative Arts Of The 20th Century And Design",
          category: "Auctions",
          city: "",
          //country: "",
          // nationality: ["US", "FR", "Irish"],
          address: "",
          // website: "",
          Date: "06/24/2021",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/event_images/Il_Ponte_Decorative_Arts_Of_The_20th_Century_and_Design.jpg",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-945843hr43",
        },
        {
          name: "Decorative Arts Of The 20th Century And Design",
          category: "Auctions",
          city: "",
          //country: "",
          // nationality: ["US", "FR", "Irish"],
          address: "",
          // website: "",
          Date: "12/17/2020",
          image:
            "https://d16kd6gzalkogb.cloudfront.net/event_images/Il_Ponte_Decorative_Arts_of_the_20th_Century_and_Design_Auction_cover_image.jpg",
          artworksCount: 0,
          instituteId: "f912b149-e7a0-49dc-bf79-945843hr43",
        },
      ],
      total: 3,
    },
  },
};

/**
 * Dummy API
 * @param {Any} axiosConfigObject
 * @param {Any} failedResponse
 */
async function apiClient(axiosConfigObject, index, failedResponse = false) {
  // console.log({ axiosConfigObject: DUMMY_API_RESPONSE[axiosConfigObject.url] });
  if (!index && index !== 0) {
    const apiResList = [];
    apiClientList.forEach((client) => {
      apiResList.push(
        new Promise((res, rej) => {
          setTimeout(() => {
            if (!failedResponse) {
              res({
                data: DUMMY_API_RESPONSE[axiosConfigObject.url],
                source: client.sourceName,
              });
            }
          }, 1000);
        })
      );
    });

    const response = await Promise.all(apiResList);

    return {
      data: response,
    };
  } else
    return new Promise((res, rej) => {
      setTimeout(() => {
        if (!failedResponse) {
          res({
            data: DUMMY_API_RESPONSE[axiosConfigObject.url],
            source: apiClientList[index].sourceName,
          });
        }
      }, 1000);
    });
}
export default apiClient;
