import { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import "./header.scss";
import { useDispatch } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import { session, headerUtils } from "../../utils";
import { resetFlag } from "../Login/logic";
import CONFIG from "../../config";

const { Header: AntHeader } = Layout;

const navMenuList = [
  {
    name: "Artworks",
    path: "/",
    title: "Artworks",

    isActive: (location) => location.pathname === "/",
  },
  {
    name: "Artists",
    path: "/artists",
    title: "Artists",
    isActive: (location) =>
      location.pathname === "/artists" || location.pathname.includes("artists"),
  },
  {
    name: "Museums",
    path: "/museum-artworks",
    title: "Museums",
    isActive: (location) =>
      location.pathname === "/museum-artworks" ||
      location.pathname.includes("museum-artworks"),
  },
  {
    name: "Gallery",
    path: "/gallery-artworks",
    title: "Gallery",
    isActive: (location) =>
      location.pathname === "/gallery-artworks" ||
      location.pathname.includes("gallery-artworks"),
  },
  {
    name: "News",
    path: "/news",
    title: "News",
    isActive: (location) =>
      location.pathname === "/news" || location.pathname.includes("news"),
  },
  {
    name: "Provenance Research",
    path: "/provenance",
    title: "Provenance",
    isActive: (location) =>
      location.pathname === "/provenance" ||
      location.pathname.includes("provenance"),
  },
  {
    name: "Lost & found",
    path: "/incidentRecords",
    title: "Lost & found",
    isActive: (location) =>
      location.pathname === "/incidentRecords" ||
      location.pathname.includes("incidentRecords"),
  },
  {
    name: "Institutes",
    path: "/institutes",
    title: "Institutes",
    isActive: (location) =>
      location.pathname === "/institutes" ||
      location.pathname.includes("/institutes") ||
      location.pathname === "/auctionEvents" ||
      location.pathname.includes("/auctionEvents"),
  },
  {
    name: "Reports",
    path: "/reporting",
    title: "Reports",
    isActive: (location) =>
      location.pathname === "/reporting" ||
      location.pathname.includes("/reporting"),
  },

  {
    name: "Monitoring",
    path: "/monitoring",
    title: "Monitoring",
    isActive: (location) =>
      location.pathname === "/monitoring" ||
      location.pathname.includes("/monitoring"),
  },

  {
    name: "Documentation",
    path: "/releaseNote",
    title: "Documentation",
    isActive: (location) =>
      location.pathname === "/releaseNote" ||
      location.pathname.includes("/releaseNote"),
  },
  {
    name: "Suggestions",
    path: "/suggestions",
    title: "View all suggestions",
    isActive: (location) =>
      location.pathname === "suggestions" ||
      location.pathname.includes("suggestions"),
  },
];
if (!CONFIG.isFinalDataOnly) {
  navMenuList.push(
    /*  {
      name: "Monitoring",
      path: "/monitoring",
      title: "Monitoring",
      isActive: (location) =>
        location.pathname === "/monitoring" ||
        location.pathname.includes("/monitoring"),
    }, */
    {
      name: "ImageSearch",
      path: "/imageSearch",
      title: "ImageSearch",
      isActive: (location) =>
        location.pathname === "/imageSearch" ||
        location.pathname.includes("/imageSearch"),
    },

    {
      name: "Settings",
      path: "/settings",
      title: "Portal Settings",
      isActive: (location) =>
        location.pathname === "settings" ||
        location.pathname.includes("settings"),
    }
  );
}
const Header = ({ history }) => {
  const dispatch = useDispatch();
  const logout = () => {
    session.clearSession(["isLoggedIn", "Token"]);
    headerUtils.discardHeader();
    dispatch(resetFlag());
    history.push("/login");
  };

  const getActiveKey = () => {
    return navMenuList
      .filter((ele) => ele.isActive(history.location))
      .map((ele) => ele.path);
  };
  const [activeMenuItem, setActiveMenuItem] = useState(getActiveKey());

  const navigateTo = ({ item, key, keyPath, domEvent }) => {
    // console.log({ item, key, keyPath, domEvent });
    if (
      history.location.pathname !== new URL(`http://test.com${key}`).pathname
    ) {
      if (key === "/login") {
        logout();
        return;
      }
      history.push(key);
    }
  };

  const renderNavItems = () => {
    return navMenuList.map((ele) => {
      const isActive = ele.isActive(history.location);
      return (
        <Menu.Item
          className={`nav-item${isActive ? " active" : ""} active`}
          key={ele.path}
          title={ele.title}
          role="presentation"
        >
          {ele.name}
        </Menu.Item>
      );
    });
  };

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      // const queryParam = new URLSearchParams(history.location.search);
      setActiveMenuItem(getActiveKey());

      // console.log({ location });
    });

    return () => {
      unlisten();
    };
  }, []);

  return (
    <AntHeader className="header">
      <div className="logo">
        <h3
          className="logo-text"
          role="presentation"
          onClick={() => {
            history.push("/");
            window.location.reload();
          }}
        >
          {process.env.REACT_APP_NAME || "Art-Data Validation Portal"}
        </h3>
      </div>
      {session.checkIfLogin() && (
        <Menu
          className="nav-menu"
          theme="dark"
          mode="horizontal"
          onClick={navigateTo}
          selectedKeys={activeMenuItem}
          overflowedIndicator={
            <span style={{ display: "flex", alignItems: "center" }}>
              More <DownOutlined style={{ padding: 4 }} />
            </span>
          }
        >
          {renderNavItems()}
          <Menu.Item key="/login" onClick={logout}>
            Logout
          </Menu.Item>
        </Menu>
      )}
    </AntHeader>
  );
};

export default Header;
