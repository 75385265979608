//import { useHistory } from "react-router";
/* eslint-disable react-hooks/rules-of-hooks */
// import { DUMMY_API_RESPONSE } from "../../utils/dummyApi";
import apiClient from "../../utils/apiClient";
import { apiBegin, apiSuccess, apiFailure } from "../../logic";
import CONFIG from "../../config";

const apiConfig = {
  /*   GET_ARTWORKS: {
    method: "get",
    url: "/artworks/",
    data: {},
  }, */
  GET_CATEGORIES: {
    method: "get",
    url: "/categories/",
    data: {},
  },
};

export const resetArtworkList = () => ({
  type: "GET_ARTWORK_RESET",
});

const getArtworkListCall = () => ({
  type: "GET_ARTWORK_API_CALL",
});

const getArtworkListSuccess = (data) => ({
  type: "GET_ARTWORK_SUCCESS",
  data,
});

const getArtworkListFailure = (error) => ({
  type: "GET_ARTWORK_FAILURE",
  error,
});

const defaultState = {
  loading: false,
  isError: false,
  data: null,
  error: null,
  flag: false,
};

const getArtworkListReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "GET_ARTWORK_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: null,
      };
    case "GET_ARTWORK_SUCCESS":
      console.log({ ...state, data: action.data });
      return {
        ...state,
        data: action.data,
        loading: false,
        isError: false,
        flag: true,
      };
    case "GET_ARTWORK_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "GET_ARTWORK_RESET":
      return {
        ...state,
        loading: false,
        error: null,
        isError: false,
        flag: false,
        data: null,
      };
    default:
      return { ...state };
  }
};

const startGetArtworkList = (query) => (dispatch) => {
  const apiPayload = { ...apiConfig.GET_ARTWORKS };
  // apiPayload.url += `${query}/`;
  dispatch(apiBegin());
  dispatch(getArtworkListCall());
  apiClient(apiPayload)
    .then((res) => {
      console.log(res.data);
      dispatch(
        getArtworkListSuccess(
          /* DUMMY_API_RESPONSE[`/file/2641ee49-7f8f-4859-a2cc-baafcf7bc21b/`]
            .data || */ res.data
        )
      );
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(getArtworkListFailure(err));
      dispatch(apiFailure(err));
    });
};

export default startGetArtworkList;

/* ----------Category List--------------- */
//const resetCategoryList = () => ({
// type: "GET_CATEGORY_RESET",
//});

const getCategoryListexportCall = () => ({
  type: "GET_CATEGORY_API_CALL",
});

const getCategoryListSuccess = (data) => ({
  type: "GET_CATEGORY_SUCCESS",
  data,
});

const getCategoryListFailure = (error) => ({
  type: "GET_CATEGORY_FAILURE",
  error,
});

const getCategoryListReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "GET_CATEGORY_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: null,
      };
    case "GET_CATEGORY_SUCCESS":
      return {
        ...state,
        data: action.data,
        loading: false,
        isError: false,
        flag: true,
      };
    case "GET_CATEGORY_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "GET_CATEGORY_RESET":
      return {
        ...state,
        loading: false,
        error: null,
        isError: false,
        flag: false,
        data: null,
      };
    default:
      return { ...state };
  }
};

export const startGetCategoryListInstitute = () => (dispatch) => {
  const apiPayload = { ...apiConfig.GET_CATEGORIES };
  // apiPayload.url += query;
  dispatch(apiBegin());
  dispatch(getCategoryListexportCall);
  apiClient(apiPayload, 0)
    .then((res) => {
      //console.log({ res });
      dispatch(getCategoryListSuccess(res.data.data));
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(getCategoryListFailure(err));
      dispatch(apiFailure(err));
    });
};

/* ------------------------------------ */

export const getUrlParam = () => {
  //  const history = useHistory();
  const searchParam = new URLSearchParams(window.location.search);
  searchParam.delete("advanceSearch");
  searchParam.delete("source");
  // searchParam.delete("artistId");
  searchParam.delete("name");
  !searchParam.has("offset") && searchParam.set("offset", 0);
  !searchParam.has("limit") &&
    searchParam.set("limit", CONFIG.isFinalDataOnly ? 18 : 6);

  CONFIG.sourceConfig.forEach((source) => {
    searchParam.delete(source.sourceName);
  });

  return searchParam.toString();
};
export const startSearch = (data) => ({
  type: "SEARCH_UPDATE",
  data,
});

const searchReducer = (state, action) => {
  if (typeof state === "undefined") {
    console.log({ search: "initial" });
    return defaultState;
  } else if (action.type === "SEARCH_UPDATE") {
    console.log({ search: "update" });
    // return { searchParam: action.data };
  } else {
    console.log("hi");
    //  return { searchParam: getUrlParam() };
  }
};

/* ------------------------------------ */

export const getArtworkListDataReducer = {
  artworkList: getArtworkListReducer,
  categoryList: getCategoryListReducer,
  searchParamData: searchReducer,
};
