//import axios from "axios";
//import { getData } from "../utils/storageService";

export const SOURCE_TYPE = {
  AUCTION_HOUSE: "Auction House",
  AGGREGATOR: "Aggregator",
  MUSEUM: "Museum",
  GALLERY: "Gallery",
  OTHER: "Other",
};

let baseUrl = "";
if (process.env.REACT_APP_ENV === "development") {
  baseUrl = process.env.REACT_APP_BACKEND_URL;
}

const CONFIG = {
  BASEURL: baseUrl,
  sourceConfig1: [],
  sourceConfig: [
    // PB Data must be on the top of the list (sourceConfig[0] is always PB Data)
    {
      sourceName: "PB Data",
      //type: SOURCE_TYPE.AGGREGATOR,
      // backendUrl: `${baseUrl}/api/v1`,
      isFinal: true,
      isActive: true,
      sourceIdentifier: "PB Data",
      sourceType: "Aggregator",
      //sourceCollectionName: "sothebys_artworks_2",
      priority: -1000000000,
    },
  ],
  isFinalDataOnly: process.env.REACT_APP_FINAL_DATA_ONLY === "true",
};
/* if (process.env.REACT_APP_FINAL_DATA_ONLY !== "true") {
  CONFIG.sourceConfig.push(
    {
      sourceName: "Bidtoart",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/bidtoart/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Museum",
      type: SOURCE_TYPE.MUSEUM,
      backendUrl: `${baseUrl}/museum/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Moma Museum",
      type: SOURCE_TYPE.MUSEUM,
      backendUrl: `${baseUrl}/moma/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Liveart",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/liveart/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Sotheby's",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/sotheby/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Christie's",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/christies/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Phillips",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/phillips/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Artcurial",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/artcurial/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Piasa",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/piasa/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Ketterer Kunst",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/ketterer/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Kornfeld Auctions",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/kornfeld/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Swan Galleries",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/swan/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Widewalls",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/widewalls/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Lempertz",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/lempertz/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Koller Artwork",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/koller/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Wikiart",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/wikiart/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Pablo Ruiz",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/pablo_ruiz/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Artsy Artwork",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/artsy_artwork/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Mutualart",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/mutualart/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Artbank",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/artbank/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Artprice",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/artprice/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Bonhams",
      type: SOURCE_TYPE.AUCTION_HOUSE,
      backendUrl: `${baseUrl}/bonhams/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "PB QA Data",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/pb_qa/api/v1`,
      isActive: true,
      isFinal: false,
    },
    {
      sourceName: "Artory",
      type: SOURCE_TYPE.AGGREGATOR,
      backendUrl: `${baseUrl}/artory_artworks/api/v1`,
      isActive: true,
      isFinal: false,
    }
  );
} */

/* export const getApiSourceList = () => {
  const URL = `${baseUrl}/api/v1/dataSources`;
 axios
    .get(URL, {
      headers: {
        Authorization: "Bearer ".concat(`${getData("Token")}`),
      },
    })
    .then((res) => {
      if (process.env.REACT_APP_FINAL_DATA_ONLY !== "true") {
        CONFIG.sourceConfig.push(...res.data.data.results);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}; */

export default CONFIG;
