//import CONFIG from "../../config";

const getUrlParam = () => {
  const searchParam = new URLSearchParams(window.location.search);
  searchParam.delete("advanceSearch");
  searchParam.delete("source");
  // searchParam.delete("artistId");
  searchParam.delete("name");
  !searchParam.has("offset") && searchParam.set("offset", 0);
  !searchParam.has("limit") && searchParam.set("limit", 10);

  return searchParam.toString();
};

export const startNewsSearch = (data) => ({
  type: "NEWS_SEARCH_UPDATE",
  data,
});

const StartNewsSearchReducer = (state, action) => {
  if (action.type === "NEWS_SEARCH_UPDATE") {
    return { searchParamForNews: action.data };
  }
  return {
    searchParamForNews: getUrlParam(),
  };
};

export const getNewsListReducer = {
  // countriesList: getCountriesListReducer,
  newsSearchParamData: StartNewsSearchReducer,
};
