//import { useSelector } from "react-redux";
//import cloneDeep from "lodash/cloneDeep";
import CONFIG from "../config";

//import { useApiSourceListData } from "./test";

import { getData, setData } from "./storageService";

export const getActiveSources = () => {
  const activeSourceList = getData("selectedSource");
  const sortedList = CONFIG.sourceConfig.sort(
    (a, b) => a.priority - b.priority
  );
  if (activeSourceList) {
    return sortedList.filter((source) =>
      activeSourceList.includes(source.sourceIdentifier)
    );
  } else {
    const allSourceList = sortedList.map((source) => source.sourceIdentifier);
    setData("selectedSource", allSourceList);
    return sortedList;
  }
};

export const allSourceMap = new Map();

export const updateAllSourceMap = () => {
  CONFIG.sourceConfig.forEach((source) =>
    allSourceMap.set(source.sourceIdentifier, source)
  );
};
