import React, { useEffect } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Layout,
  message,
  notification,
  Result,
  Space,
  Spin,
} from "antd";
import "./App.scss";
import Header from "./component/Header";
import { headerUtils, session } from "./utils";
import { resetFlag } from "./component/Login/logic";
import { resetCommon } from "./logic";
import AuthRoute from "./component/AuthRoute";
import { startGetApiSourceList } from "./component/Settings/logic";
import { startGetCategoryList } from "./component/SearchAndResult/logic";
// SearchAndResultInstitute
import { startGetCategoryListInstitute } from "./component/SearchAndResultInstitute/logic";

import { startGetCategoryListMuseum } from "./component/Museum/logic";
import { startGetCategoryListGallery } from "./component/Gallery/logic";
import { startGetCountriesList } from "./component/ArtistSearchAndResult/logic";
import { startGetReportTypeList } from "./component/Reports/logic";
import { checkIfLogin } from "./utils/sessionManagement";
import ErrorBoundary from "./component/ErrorBoundries";

const Login = React.lazy(() => import("./component/Login"));
const SearchAndResult = React.lazy(() => import("./component/SearchAndResult"));
const SearchAndResultInstitute = React.lazy(() =>
  import("./component/SearchAndResultInstitute")
);
const Museum = React.lazy(() => import("./component/Museum"));
const Gallery = React.lazy(() => import("./component/Gallery"));
const Details = React.lazy(() => import("./component/Details"));
const DetailsMuseum = React.lazy(() => import("./component/DetailsMuseum"));
const DetailsGallery = React.lazy(() => import("./component/DetailsGallery"));
const Settings = React.lazy(() => import("./component/Settings"));
const SuggestionView = React.lazy(() => import("./component/Suggestions"));
const ArtistSearchAndResult = React.lazy(() =>
  import("./component/ArtistSearchAndResult")
);
const ArtistDetails = React.lazy(() => import("./component/ArtistDetail"));
const News = React.lazy(() => import("./component/NewsPage"));
const NewsDetails = React.lazy(() => import("./component/NewsDetail"));
const Provenance = React.lazy(() => import("./component/ProvenanceResearch"));
const ProvenanceDetails = React.lazy(() =>
  import("./component/ProvenanceDetails")
);

const LostFoundRecords = React.lazy(() =>
  import("./component/LostnFoundRecords")
);
const LostNFoundDetails = React.lazy(() =>
  import("./component/LostnFoundDetail")
);
const ImageSearchNResults = React.lazy(() => import("./component/ImageSearch"));
const ImageSearchDetails = React.lazy(() =>
  import("./component/ImageSearchDetail")
);
const ViewTable = React.lazy(() => import("./component/TableData"));
const ReleaseNotes = React.lazy(() => import("./component/ReleaseNotes"));
const qualityReports = React.lazy(() => import("./component/Reports"));
const ReportInfo = React.lazy(() => import("./component/ReportInfo"));
const AuctionHouse = React.lazy(() => import("./component/AuctionHouse"));
const InstituteDetails = React.lazy(() =>
  import("./component/AuctionHouseDetails")
);
const AuctionEventDetails = React.lazy(() =>
  import("./component/AuctionHouseDetails/AuctionEventDetails")
);
const AuctionInstituteDetails = React.lazy(() =>
  import("./component/AuctionHouseDetails/AuctionInstituteDetails")
);

const CompareView = React.lazy(() => import("./component/Compare"));

const history = createBrowserHistory({ basename: "/" });

const { Content } = Layout;
function App() {
  const dispatch = useDispatch();
  const { isError, error } = useSelector((state) => state.apiStatus);
  const { flag } = useSelector((state) => state.userData);
  const { loading: optionLoading, flag: optionFlag } = useSelector(
    (state) => state.categoryList
  );
  const { loading: artistLoading, flag: artistFlag } = useSelector(
    (state) => state.countriesList
  );
  const { loading: dataSourceListLoading, flag: dataSourceFlag } = useSelector(
    (state) => state.apiSourceListData
  );
  const { loading: reportListLoading, flag: reportListFlag } = useSelector(
    (state) => state.reportTypeList
  );

  useEffect(() => {
    if (flag && !dataSourceFlag && !dataSourceListLoading && checkIfLogin()) {
      dispatch(startGetApiSourceList());
    }
    if (flag && !optionFlag && !optionLoading && checkIfLogin()) {
      dispatch(startGetCategoryList());
    }
    if (flag && !optionFlag && !optionLoading && checkIfLogin()) {
      dispatch(startGetCategoryListInstitute());
    }
    if (flag && !optionFlag && !optionLoading && checkIfLogin()) {
      dispatch(startGetCategoryListMuseum());
    }
    if (flag && !optionFlag && !optionLoading && checkIfLogin()) {
      dispatch(startGetCategoryListGallery());
    }
    if (flag && !reportListFlag && !reportListLoading && checkIfLogin()) {
      dispatch(startGetReportTypeList());
    }
    if (flag && !artistFlag && !artistLoading && checkIfLogin()) {
      dispatch(startGetCountriesList());
    }
  }, [flag]);
  useEffect(() => {
    if (isError) {
      const response = error.response;

      if (response) {
        if (response.status === 401) {
          session.clearSession(["isLoggedIn", "Token"]);
          headerUtils.discardHeader();
          dispatch(resetFlag());
          if (history.location.pathname !== "/login") {
            history.push("/login");
            window.location.reload();
          }
          message.error("UnAuthorized");
        } else if (response.status === 504) {
          message.error({
            key: "504",
            content: "Request timeout",
            onClick: () => message.destroy("504"),
          });
        } else if (response.status === 400) {
          message.error({
            key: "400",
            content: response?.data?.field?.[0].message || "Bad Request",
            onClick: () => message.destroy("400"),
          });
        } else if (response.status === 404) {
          message.error(response?.data?.message || "Record not found");
        } else if (response.status === 502) {
          notification.warning({
            message: "Deployment in progress",
            description:
              "We are upgrading our system. Please try after some time. Sorry for inconvenience 😔",
            key: "deploymentNotification",
          });
        }
      } else {
        message.error("Something went wrong please try again after some time");
      }
      dispatch(resetCommon());
    }
  }, [isError, error]);

  useEffect(() => {
    message.config({
      top: 100,
      duration: 5,
      maxCount: 1,
      rtl: true,
    });
    notification.config({
      placement: "topRight",
      top: 50,
      duration: 10,
      rtl: false,
      maxCount: 1,
    });
  }, []);

  return (
    <Layout className="App">
      <Header history={history} />
      <Content className="main-section">
        <ErrorBoundary>
          <React.Suspense
            fallback={
              <Space size="large">
                <Spin size="large" />
              </Space>
            }
          >
            <Router history={history}>
              <Switch>
                <Route path="/login" exact component={Login} />
                <AuthRoute path="/" exact component={SearchAndResult} />
                <AuthRoute path="/museum-artworks" exact component={Museum} />
                <AuthRoute path="/gallery-artworks" exact component={Gallery} />
                <AuthRoute
                  path="/artwork/:artworkId/details/"
                  component={Details}
                />
                <AuthRoute path="/settings" component={Settings} />
                <AuthRoute path="/suggestions" component={SuggestionView} />
                <AuthRoute
                  path="/artwork/:artworkId/details/"
                  component={Details}
                />
                <AuthRoute
                  path="/museum-artworks/:artworkId/details/"
                  component={DetailsMuseum}
                />
                <AuthRoute
                  path="/gallery-artworks/:artworkId/details/"
                  component={DetailsGallery}
                />
                <AuthRoute
                  path="/artists"
                  exact
                  component={ArtistSearchAndResult}
                />
                <AuthRoute
                  path="/artists/:artistId/details"
                  component={ArtistDetails}
                />
                <AuthRoute exact path="/news" component={News} />
                <AuthRoute
                  path="/news/:newsId/details"
                  component={NewsDetails}
                />

                <AuthRoute exact path="/provenance" component={Provenance} />
                <AuthRoute
                  path="/provenance/:provenanceId/details"
                  component={ProvenanceDetails}
                />

                <AuthRoute
                  exact
                  path="/incidentRecords"
                  component={LostFoundRecords}
                />
                <AuthRoute
                  path="/incidentRecords/:incidentId/details"
                  component={LostNFoundDetails}
                />
                <AuthRoute
                  exact
                  path="/imageSearch"
                  component={ImageSearchNResults}
                />
                <AuthRoute
                  path="/imageSearch/:imageSearchId/details"
                  component={ImageSearchDetails}
                />
                <AuthRoute path="/monitoring" component={ViewTable} />
                <AuthRoute path="/releaseNote" component={ReleaseNotes} />
                <AuthRoute exact path="/reporting" component={qualityReports} />
                <AuthRoute
                  path="/reporting/:reportId/details"
                  component={ReportInfo}
                />
                <AuthRoute exact path="/institutes" component={AuctionHouse} />
                <AuthRoute
                  path="/institutes/:instituteId/details"
                  component={InstituteDetails}
                />
                <AuthRoute
                  path="/institutes/:instituteId/artw"
                  component={SearchAndResultInstitute}
                />

                <AuthRoute
                  path="/institutes/:instituteId/artworks"
                  component={AuctionInstituteDetails}
                />
                <AuthRoute
                  path="/auctionEvents/:eventId/details"
                  component={AuctionEventDetails}
                />
                <AuthRoute exact path="/compare" component={CompareView} />

                <Route
                  component={() => (
                    <Result
                      status="404"
                      title="404"
                      subTitle="Sorry, the page you visited does not exist."
                      extra={
                        <Button
                          type="primary"
                          onClick={() => history.push("/")}
                        >
                          Back Home
                        </Button>
                      }
                    />
                  )}
                />
              </Switch>
            </Router>
          </React.Suspense>
        </ErrorBoundary>
      </Content>
      {/*  <Footer></Footer> */}
    </Layout>
  );
}

export default App;
