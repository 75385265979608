const getUrlParam = () => {
  const searchParam = new URLSearchParams(window.location.search);
  searchParam.delete("advanceSearch");
  searchParam.delete("source");
  !searchParam.has("offset") && searchParam.set("offset", 0);
  !searchParam.has("limit") && searchParam.set("limit", 6);

  return searchParam.toString();
};
export const startImageSearch = (data) => ({
  type: "IMAGE_SEARCH_UPDATE",
  data,
});

const StartImageSearchReducer = (state, action) => {
  if (action.type === "IMAGE_SEARCH_UPDATE") {
    return { searchParam: action.data };
  }
  return {
    searchParam: getUrlParam(),
  };
};

export const getImageListReducer = {
  ImageSearchParamData: StartImageSearchReducer,
};
