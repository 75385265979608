const defaultCompareListState = {
  data: [],
};
export const updateCompareList = (updatedList) => ({
  type: "UPDATE_COMPARE_LIST",
  data: updatedList,
});

export const resetCompareList = () => ({
  type: "RESET_COMPARE_LIST",
});

const compareListReducer = (state = defaultCompareListState, action) => {
  switch (action.type) {
    case "UPDATE_COMPARE_LIST": {
      return {
        ...state,
        data: action.data,
      };
    }
    case "RESET_COMPARE_LIST": {
      return {
        ...defaultCompareListState,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export const compareReducer = {
  compareList: compareListReducer,
};
