const getUrlParam = () => {
  const searchParam = new URLSearchParams(window.location.search);
  searchParam.delete("advanceSearch");
  searchParam.delete("source");
  searchParam.delete("name");
  //  searchParam.delete("title");

  !searchParam.has("offset") && searchParam.set("offset", 0);
  !searchParam.has("limit") && searchParam.set("limit", 10);

  return searchParam.toString();
};
export const startLostNFoundSearch = (data) => ({
  type: "LOST_AND_FOUND_SEARCH_UPDATE",
  data,
});

const StartLostNFoundSearchReducer = (state, action) => {
  if (action.type === "LOST_AND_FOUND_SEARCH_UPDATE") {
    return { searchParamForLost: action.data };
  }
  return {
    searchParamForLost: getUrlParam(),
  };
};

export const getLostNFoundListReducer = {
  // countriesList: getCountriesListReducer,
  lostAndFoundSearchParamData: StartLostNFoundSearchReducer,
};
