import apiClient from "../../utils/dummyApi";

import { apiBegin, apiSuccess, apiFailure } from "../../logic";

const apiConfig = {
  GET_ARTWORK_DETAILS: {
    method: "get",
    url: "/artworks/{ARTWORK_ID}/details/",
    data: {},
  },
};

export const resetArtworkDetails = () => ({
  type: "GET_ARTWORK_DETAILS_RESET",
});

const getArtworkDetailsCall = () => ({
  type: "GET_ARTWORK_DETAILS_API_CALL",
});

const getArtworkDetailsSuccess = (data) => ({
  type: "GET_ARTWORK_DETAILS_SUCCESS",
  data,
});

const getArtworkDetailsFailure = (error) => ({
  type: "GET_ARTWORK_DETAILS_FAILURE",
  error,
});

const defaultState = {
  loading: false,
  isError: false,
  data: null,
  error: null,
  flag: false,
};

const getArtworkDetailsReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "GET_ARTWORK_DETAILS_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: null,
      };
    case "GET_ARTWORK_DETAILS_SUCCESS":
      return {
        ...state,
        data: action.data,
        loading: false,
        isError: false,
        flag: true,
      };
    case "GET_ARTWORK_DETAILS_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "GET_ARTWORK_DETAILS_RESET":
      return {
        ...state,
        loading: false,
        error: null,
        isError: false,
        flag: false,
        data: null,
      };
    default:
      return { ...state };
  }
};

const startGetArtworkDetails = (fileId, query) => (dispatch) => {
  const apiPayload = { ...apiConfig.GET_ARTWORK_DETAILS };
  apiPayload.url = apiPayload.url.replace("{ARTWORK_ID}", fileId);
  dispatch(apiBegin());
  dispatch(getArtworkDetailsCall());
  console.log(apiPayload);
  apiClient(apiPayload, 0)
    .then((res) => {
      dispatch(
        getArtworkDetailsSuccess(
          /* DUMMY_API_RESPONSE[`/file/2641ee49-7f8f-4859-a2cc-baafcf7bc21b/`]
            .data || */ res.data.data
        )
      );
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(getArtworkDetailsFailure(err));
      dispatch(apiFailure(err));
    });
};

export default startGetArtworkDetails;

export const artworkDetailReducer = {
  artworkDetails: getArtworkDetailsReducer,
};
