import apiClient from "../../utils/apiClient";
//import apiClient from "../../utils/dummyApi";
import { apiBegin, apiSuccess, apiFailure } from "../../logic";

//import CONFIG from "../../config";

const apiConfig = {
  GET_REPORT_TYPE: {
    method: "get",
    url: "/reports/type",
    data: {},
  },
};

//const resetCountriesList = () => ({
//  type: "GET_REPORT_TYPE_RESET",
//});

const getReportTypeexportCall = () => ({
  type: "GET_REPORT_TYPE_API_CALL",
});

const getReportTypeSuccess = (data) => ({
  type: "GET_REPORT_TYPE_SUCCESS",
  data,
});

const getReportTypeFailure = (error) => ({
  type: "GET_REPORT_TYPE_FAILURE",
  error,
});

export const PostReportTypeSuccess = (data) => ({
  type: "POST_REPORT_TYPE_SUCCESS",
  data,
});

const defaultState = {
  loading: false,
  isError: false,
  data: null,
  error: null,
  flag: false,
};
const defaultStateForPost = {
  loading: false,
  isError: false,
  data: null,
  error: null,
  flag: false,
};

const PostReportTypeReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultStateForPost;
  }
  switch (action.type) {
    case "POST_REPORT_TYPE_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: null,
      };
    case "POST_REPORT_TYPE_SUCCESS":
      return {
        ...state,
        data: action.data,
        loading: false,
        isError: false,
        flag: true,
      };
    case "POST_REPORT_TYPE_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "POST_REPORT_TYPE_RESET":
      return {
        ...state,
        loading: false,
        error: null,
        isError: false,
        flag: false,
        data: null,
      };
    default:
      return { ...state };
  }
};

const getReportTypeReducer = (state, action) => {
  if (typeof state === "undefined") {
    return defaultState;
  }
  switch (action.type) {
    case "GET_REPORT_TYPE_API_CALL":
      return {
        ...state,
        loading: true,
        isError: false,
        flag: false,
        error: null,
      };
    case "GET_REPORT_TYPE_SUCCESS":
      return {
        ...state,
        data: action.data,
        loading: false,
        isError: false,
        flag: true,
      };
    case "GET_REPORT_TYPE_FAILURE":
      return {
        ...state,
        loading: false,
        isError: true,
        error: action.error,
      };
    case "GET_REPORT_TYPE_RESET":
      return {
        ...state,
        loading: false,
        error: null,
        isError: false,
        flag: false,
        data: null,
      };
    default:
      return { ...state };
  }
};

export const startGetReportTypeList = () => (dispatch) => {
  const apiPayload = { ...apiConfig.GET_REPORT_TYPE };
  // apiPayload.url += query;
  dispatch(apiBegin());
  dispatch(getReportTypeexportCall());
  apiClient(apiPayload, 0)
    .then((res) => {
      dispatch(getReportTypeSuccess(res.data.data.results));
      dispatch(apiSuccess());
    })
    .catch((err) => {
      dispatch(getReportTypeFailure(err));
      dispatch(apiFailure(err));
    });
};

export const getReportTypeListReducer = {
  reportTypeList: getReportTypeReducer,
  postReportData: PostReportTypeReducer,
};
