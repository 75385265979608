import { combineReducers } from "redux";
import { commonApiStatusReducer } from "../logic";
import { loginDataReducer } from "../component/Login/logic";
import { getArtworkListDataReducer } from "../component/SearchAndResult/logic";
import { artworkDetailReducer } from "../component/Details/logic";
import { getArtistListReducer } from "../component/ArtistSearchAndResult/logic";
import { artistDetailReducer } from "../component/ArtistDetail/logic";
import { getNewsListReducer } from "../component/NewsPage/logic";
import { newsDetailReducer } from "../component/NewsDetail/logic";
import { getLostNFoundListReducer } from "../component/LostnFoundRecords/logic";
import { getImageListReducer } from "../component/ImageSearch/logic";
import { FilterTableReducer } from "../component/TableData/logic";
import { getReportTypeListReducer } from "../component/Reports/logic";
import { getApiSourceListDataReducer } from "../component/Settings/logic";
import { compareReducer } from "../component/Compare/logic";

export default combineReducers({
  ...commonApiStatusReducer,
  ...loginDataReducer,
  ...getApiSourceListDataReducer,
  ...getArtworkListDataReducer,
  ...artworkDetailReducer,
  ...artistDetailReducer,
  ...getArtistListReducer,
  ...getNewsListReducer,
  ...newsDetailReducer,
  ...getLostNFoundListReducer,
  ...getImageListReducer,
  ...FilterTableReducer,
  ...getReportTypeListReducer,
  ...compareReducer,
});
