import axios from "axios";
import CONFIG from "../config";
import { getAuthHeader } from "./sessionManagement";
import { getActiveSources } from "./sourceUtils";
//import { getData } from "./storageService";

export const apiClientSourceMapping = new Map();

export const getApiClientAndUpdateMapping = () => {
  const clientList = getActiveSources().map((source) => {
    const sourceApiClient = axios.create({
      // baseURL: source.backendUrl,
      baseURL: `${CONFIG?.BASEURL}/api/v1`,
    });
    apiClientSourceMapping.set(source?.sourceIdentifier, sourceApiClient);
    // if (source.isFinal) {
    sourceApiClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${getAuthHeader()}`;

    if (!source.isFinal) {
      sourceApiClient.defaults.headers.common[
        "x-source"
      ] = `${source?.sourceIdentifier}`;
    }
    // }
    return sourceApiClient;
  });
  return clientList;
};
const apiClientList = getApiClientAndUpdateMapping();

// const apiClient = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api/v0",
// });

export const setHeader = (token) => {
  apiClientList.forEach((client) => {
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
  });
};

export const discardHeader = () => {
  apiClientList.forEach((client) => {
    delete client.defaults.headers.common.Authorization;
  });
};

apiClientList.forEach((client) => {
  client.defaults.headers.common.Authorization = `Bearer ${getAuthHeader()}`;
});

/**
 * @type {import("axios").AxiosInstance}
 */
// export default apiClient;

function mapSourceWiseResponse(allResponseList) {
  return CONFIG.sourceConfig.map((ele, index) => ({
    source: ele?.sourceIdentifier,
    data: allResponseList[index].data,
  }));
}
const apiClient = async (config, index) => {
  try {
    if (!index && index !== 0) {
      const callList = [];
      apiClientList.forEach((client) => {
        callList.push(client(config));
      });
      const response = await Promise.all(callList);
      return { data: mapSourceWiseResponse(response) };
    } else {
      const response = await apiClientList[index](config);
      return {
        data: response.data,
        source: CONFIG.sourceConfig[index]?.sourceIdentifier,
      };
    }
  } catch (error) {
    throw error;
  }
};

export const updateApiClientMapping = () => {
  apiClientSourceMapping.clear();
  getApiClientAndUpdateMapping();
};

export default apiClient;
