import { deleteData, getData, removeItem, setData } from "./storageService";

export const clearSession = (removeList) => {
  if (removeList) {
    removeList.forEach((key) => {
      removeItem(key);
    });
  } else {
    deleteData();
  }
};

export const addSession = (session) => {
  setData("isLoggedIn", true);
  setData(`Token`, session.token);
};

//TODO: CHange default value to false
export const checkIfLogin = () => getData("isLoggedIn") || false;

export const getAuthHeader = () => getData(`Token`);
